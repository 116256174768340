// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  version: "6.5 1 Junio 2024 (Beta)",
  firebase: {
    apiKey: "AIzaSyAmAnBBefe_f6rwSLIUK0e1JexuDGP2w_4",
    authDomain: "julsmind-katuq.firebaseapp.com",
    projectId: "julsmind-katuq",
    storageBucket: "julsmind-katuq.appspot.com",
    messagingSenderId: "262274219539",
    appId: "1:262274219539:web:bd63a33f16779c7d4689e6",
    measurementId: "G-LFYSZ78R7F"
  },
  urlPermitidas: 'https://sellercenter.katuq.com',
  // urlApi: 'http://127.0.0.1:5001/julsmind-katuq/us-central1/api',
  urlApi: 'https://us-central1-julsmind-katuq.cloudfunctions.net/api',
  wompi: {
    prod_intrity: 'test_integrity_gD6XzNRKmItupFPhyEj09rycF7orECGD',
    public_key_test: 'pub_test_sNdWRfLNp683Ex0hLby4nxcOBIkH38Jy',
    public_key: 'pub_prod_cN70rb6aXdHMiBWj9fwY26Xyh1Oz5PUf',
    event: 'test_events_AaaZdg5VB8tngBhZkogbXmDpbT9nWMH5'
  }

};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

import { Routes } from '@angular/router';


export const content: Routes = [
  {
    path: 'sample-page',
    loadChildren: () => import('../../components/sample/sample.module').then(m => m.SampleModule)
  },
  {
    path: 'maestros',
    loadChildren: () => import('../../components/maestros/maestros.module').then(m => m.MaestrosModule)
  },
  {
    path: 'usuarios',
    loadChildren: () => import('../../components/usuarios/usuarios.module').then(m => m.UsuariosModule)
  },
  {
    path: 'productos',
    loadChildren: () => import('../../components/productos/productos.module').then(m => m.ProductosModule)
  },
  {
    path: 'productos/crearProductos',
    loadChildren: () => import('../../components/productos/crear-productos/crear-productos.module').then(m => m.ProductosModule)
  },
  {
    path: 'usuarios/crearUsuario',
    loadChildren: () => import('../../components/usuarios/crear-usuarios/crear-usuarios.module').then(m => m.CrearUsuariosModule)
  },
  {
    path: 'rol',
    loadChildren: () => import('../../components/rol/rol.module').then(m => m.RolModule)
  },
  {
    path: 'empresas',
    loadChildren: () => import('../../components/empresas/empresas.module').then(m => m.EmpresasModule)
  },
  {
    path: 'proceso',
    loadChildren: () => import('../../components/proceso/proceso.module').then(m => m.ProcesoModule)
  },
  {
    path: 'extras',
    loadChildren: () => import('../../components/extras/extras.module').then(m => m.ExtrasModule)
  },
  {
    path: 'empresas/crearEmpresa',
    loadChildren: () => import('../../components/empresas/crearEmpresa/crear-empresa/crear-empresa.module').then(m => m.CrearEmpresasModule)
  },
  {
    path: 'roles/crearRol',
    loadChildren: () => import('../../components/rol/crear-rol/crear-rol.module').then(m => m.CrearRolModule)
  },
  {
    path: 'formasEntrega',
    loadChildren: () => import('../../components/formas-entrega/formas-entrega.module').then(m => m.FormasEntregaModule)
  },
  {
    path: 'ventas',
    loadChildren: () => import('../../components/ventas/ventas.module').then(m => m.VentasModule)
  },
  {
    path: 'tiempoentrega',
    loadChildren: () => import('../../components/tiempos-entrega/tiempoentrega.module').then(m => m.TiempoEntregaModule)
  } ,
  {
    path: 'categorias',
    loadChildren: () => import('../../components/ecomerce/categorias/categorias.module').then(m => m.CategoriasModule)
  } ,
  {
    path: 'ecommerce',
    loadChildren: () => import('../../components/ecomerce/ecommerce.module').then(m => m.EcommeceModule)
  } ,
  {
    path:'produccion',
    loadChildren: () => import('../../components/produccion/produccion.module').then(m => m.ProduccionModule)
  },
  {
    path:'despachos',
    loadChildren: () => import('../../components/despachos/despachos.module').then(m => m.DespachosModule)
  }
];

import { Component, OnInit } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-images-manager',
  templateUrl: './images-manager.component.html',
  styleUrls: ['./images-manager.component.scss']
})
export class ImagesManagerComponent implements OnInit {

  uploadProgress: number;
  tasks: AngularFireUploadTask[] = [];

  constructor(private storage: AngularFireStorage) { }
  
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  uploadFiles(event) {
    const files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const filePath = `files/${new Date().getTime()}_${file.name}`;
      const task = this.storage.upload(filePath, file);

      // keep track of all tasks so we can run them all concurrently
      this.tasks.push(task);

      // observe percentage changes to update the progress value
      task.percentageChanges().subscribe((percentage) => {
        if (percentage === 100) {
          Swal.fire('Éxito', 'Archivo subido con éxito', 'success');
        }
        this.uploadProgress = percentage;
      }, error => Swal.fire('Error', 'Ocurrió un error al subir el archivo: ' + error.message, 'error'));
    }

    // execute all the tasks concurrently
    this.runUploadTasks();
  }

  runUploadTasks() {
    // convert Observable into a promise
    const allTasks = this.tasks.map(task => task.snapshotChanges().toPromise());

    // run all upload tasks
    Promise.all(allTasks)
      .then(() => Swal.fire('Éxito', 'Todos los archivos han sido subidos con éxito', 'success'))
      .catch((error) => Swal.fire('Error', 'Ocurrió un error al subir los archivos: ' + error.message, 'error'));
  }

}

import { Component, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { SwUpdate } from '@angular/service-worker';
import Swal from 'sweetalert2';
import { env } from 'process';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService, translate: TranslateService, private updates: SwUpdate) {
    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang('es');
      translate.addLangs(['en', 'de', 'es', 'fr', 'pt', 'cn', 'ae']);
    }
    // if (environment.production) {
      updates.checkForUpdate().then(() => { }
        // this.updateCheckText = 'resolved';
      ).catch(err => { }
        // this.updateCheckText = rejected: ${ err.message }
      );

      updates.available.subscribe(async event => {
        // console.log('current version is', event.current);
        // console.log('available version is', event.available);
        Swal.fire({
          title: 'Nueva Versión',
          text: '¡Debes actualizar a la nueva versión!',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {

            updates.activateUpdate().then(() => document.location.reload());

          }
        })

      });

      updates.activated.subscribe(event => {
        // console.log('old version was', event.previous);
        // console.log('new version is', event.current);S
        Swal.fire({
          icon: 'success',
          title: '¡Aplicación actualizada!',
          showConfirmButton: true,
          timer: 1500
        });
      });
    // }

  }
}





import { Injectable } from '@angular/core';
import { UserLogged } from '../../models/User/UserLogged';
import { CompanyInformation } from '../../models/User/CompanyInformation';
import { Empresa } from '../../models/empresa/empresa';

@Injectable({ providedIn: 'root' })
export class SecurityService {
    constructor() { }


    getCompanyInformationLogged(): CompanyInformation {
        const currentCompany = sessionStorage.getItem('currentCompany');
        if (currentCompany) {
            const empresa: Empresa = JSON.parse(currentCompany);
            return {
                nombreComercio: empresa.nomComercial,
                imgUrlLogo: empresa.logo,
                razonSocial: empresa.nombre
            };

        }


    }

}
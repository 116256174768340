<div class="container-fluid p-0">
  <div class="row login-row">
    <div class="col-md-7 p-0">
      <div class="login-image">
        <!-- Si deseas mantener la imagen, sino puedes omitir esta línea -->
        <!-- <img src="assets/images/login/login_image.jpg" alt="Login Image" class="img-fluid"> -->
      </div>
    </div>
    <div class="col-md-5 p-0">
      <!-- <div class="login-content">
        <div>
          <div>
            <a class="logo" routerLink='/'>
              <img class="img-fluid for-light" src="assets/images/logo/Katuq/katuq_dark.svg" alt="login page" style="max-width: 60%; max-height: 60%;">
              <img class="img-fluid for-dark" src="assets/images/logo/Katuq/katuq_light.svg" alt="login page">
            </a>
          </div>
          <div class="login-main">
            <form class="theme-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
              <h4>Ingresa a tu cuenta</h4>
              <p>Ingresa tu correo y tu contraseña</p>
              <div class="form-group">
                <label class="col-form-label">Correo Electrónico</label>
                <input class="form-control" type="email" required="" placeholder="app@yourmail.com" formControlName="email">
                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required" class="text text-danger mt-1">
                  Correo es requerido
                </div>
                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email" class="text text-danger mt-1">
                  Correo inválido
                </div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Contraseña</label>
                <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password" required="" placeholder="*********">
                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="hide"></span></div>
                <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required" class="text text-danger mt-1">
                  Contraseña es requerida
                </div>
              </div>
              <div class="form-group mb-0">
                <button class="btn btn-primary d-block w-100" data-callback='onSubmit' data-action='submit' type="submit" id="loginButton" [disabled]="!loginForm.valid" mat-raised-button color="primary" (click)="onSubmit()">
                  <span class="text-white-color">
                    Ingresar
                  </span>
                </button>
                <h5 class="text-center mt-5">V. {{version}}</h5>
              </div>
            </form>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>

import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';

// Menu
export interface Menu {
	headTitle1?: string,
	headTitle2?: string,
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
	isOnlySuperAdministrador?: boolean;
}

@Injectable({
	providedIn: 'root'
})

export class NavService implements OnDestroy {

	private unsubscriber: Subject<any> = new Subject();
	public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

	// Search Box
	public search: boolean = false;

	// Language
	public language: boolean = false;

	// Mega Menu
	public megaMenu: boolean = false;
	public levelMenu: boolean = false;
	public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

	// For Horizontal Layout Mobile
	public horizontal: boolean = window.innerWidth < 991 ? false : true;

	// Collapse Sidebar
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

	isAdmin: boolean = JSON.parse(localStorage.getItem('user')).rol === 'Administrador' && JSON.parse(localStorage.getItem('user')).company == 'Julsmind' ? true : false;
	// Full screen
	public fullScreen: boolean = false;

	constructor(private router: Router) {
		this.setScreenWidth(window.innerWidth);
		fromEvent(window, 'resize').pipe(
			debounceTime(1000),
			takeUntil(this.unsubscriber)
		).subscribe((evt: any) => {
			this.setScreenWidth(evt.target.innerWidth);
			if (evt.target.innerWidth < 991) {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			}
			if (evt.target.innerWidth < 1199) {
				this.megaMenuColapse = true;
			}
		});
		if (window.innerWidth < 991) { // Detect Route change sidebar close
			this.router.events.subscribe(event => {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			});
		}
	}

	ngOnDestroy() {
		this.unsubscriber.complete();
	}

	private setScreenWidth(width: number): void {
		this.screenWidth.next(width);
	}

	MENUITEMS: Menu[] = [
		{
			headTitle1: 'Comercio',
			isOnlySuperAdministrador: true
		},
		{
			title: 'Seguridad*', icon: 'shield', type: 'sub', badgeType: 'success', badgeValue: '', active: false, children: [
				{ path: 'rol', title: 'Roles*', type: 'link' },
				{ path: 'usuarios', title: 'Usuarios*', type: 'link' }
			],
			isOnlySuperAdministrador: true
		},
		{
			title: 'Empresa', icon: 'home', type: 'sub', badgeType: 'success', badgeValue: '', active: false, children: [

				{ path: 'empresas', title: 'Empresas', type: 'link' },
				{ path: 'empresas', title: 'Módulos Fijos*', type: 'link' },
				{ path: 'empresas/modulovariable/produccion/opciones', title: 'Módulos Variables', type: 'link' },
				{ path: 'empresas', title: 'Módulos Adicionales*', type: 'link' },
				{ path: 'empresas', title: 'Modulos Aliados*', type: 'link' },
				{ path: 'empresas', title: 'Integraciones*', type: 'link' },
			]
		},
		{
			title: 'Notificaciones*', icon: 'message-circle', type: 'sub', badgeType: 'success', badgeValue: '', active: false, children: [
				{ path: 'notificaciones', title: 'Notificaciones*', type: 'link' }
			]
			,isOnlySuperAdministrador: true
		},
		{
			headTitle1: 'Configuración Tienda'
		},
		{
			title: 'Entregas', icon: 'map-pin', type: 'sub', badgeType: 'success', badgeValue: '', active: false, children: [
				{ path: 'formasEntrega', title: 'Formas Entrega', type: 'link' },
				{ path: 'formasEntrega/tipoentrega/lista', title: 'Tipo de Entrega', type: 'link' },
				{ path: 'tiempoentrega', title: 'Tiempos de entrega', type: 'link' }
			]
		},
		{
			title: 'Promociones', icon: 'chevrons-down', type: 'sub', badgeType: 'success', badgeValue: '', active: false, children: [
				{ path: '', title: 'Generales %$*', type: 'link' },
				{ path: '', title: 'Envios gratis %$*', type: 'link' },
				{ path: '', title: 'Códigos %$*', type: 'link' },
				{ path: '', title: 'Bonos de Regalo $*', type: 'link' },
				

			],isOnlySuperAdministrador: true
		},
		{
			title: 'Funcionalidades*', icon: 'check-square', type: 'sub', badgeType: 'success', badgeValue: '', active: false, children: [

				{ path: '', title: 'Bloqueo fechas y horarios*', type: 'link' },
				{ path: '', title: 'Cambio precios*', type: 'link' }
			],
			isOnlySuperAdministrador: true
		},
		{
			title: 'Proceso', icon: 'settings', type: 'sub', badgeType: 'success', badgeValue: '', active: false, children: [

				{ path: 'proceso/ocasiones', title: 'Ocasiones', type: 'link' },
				{ path: 'proceso/generos', title: 'Géneros', type: 'link' },

			]
		},
		{
			title: 'Catalogo', icon: 'book', type: 'sub', badgeType: 'success', badgeValue: '', active: false, children: [

				{ path: 'productos', title: 'Productos', type: 'link' },
				{ path: 'categorias', title: 'Categorías', type: 'link' },
				{ path: 'ecommerce/adiciones/listar', title: 'Adiciones', type: 'link' },
				{ path: '', title: 'Lista Lite*', type: 'link' }

			]
		},
		{
			title: 'Extras', icon: 'star', type: 'sub', badgeType: 'success', badgeValue: '', active: false, children: [

				{ path: 'extras/formasPago', title: 'Formas de pago', type: 'link' },
				{ path: 'extras/zonasCobro', title: 'Zonas Cobro', type: 'link' }

			]
		},
		{
			headTitle1: 'Gestor de Pedidos'
		},
		{
			title: 'Ventas', icon: 'dollar-sign', type: 'sub', badgeType: 'success', badgeValue: '', active: false, children: [
				{ path: 'ventas/clientes', title: 'Clientes', type: 'link' },
				{ path: 'ventas/crear-ventas', title: 'Crear', type: 'link' },
				{ path: '**', title: 'Dashboard', type: 'link' },
				// { path: 'ventas/pedidos', title: 'Pedidos', type: 'link' },

			]
		},
		{
			title: 'Pedidos', icon: 'tablet', type: 'sub', badgeType: 'success', badgeValue: '', active: false, children: [
				{ path: 'ventas/pedidos', title: 'Pedidos', type: 'link' },
			]
		},
		{
			title: 'Tesoreria', icon: 'book', type: 'sub', badgeType: 'success', badgeValue: '', active: false, children: [
				{ path: '', title: 'Tesoreria', type: 'link' },
			],
			isOnlySuperAdministrador: true
		},
		{
			title: 'Produccion', icon: 'archive', type: 'sub', badgeType: 'success', badgeValue: '', active: false, children: [
				{ path: 'produccion/dashboard', title: 'Tablero', type: 'link' }
			]
		},
		{
			title: 'Despachos', icon: 'truck', type: 'sub', badgeType: 'success', badgeValue: '', active: false, children: [
				{ path: 'despachos', title: 'Despachos', type: 'link' },
			]
		},
		{
			title: 'Entregas', icon: 'box', type: 'sub', badgeType: 'success', badgeValue: '', active: false, children: [
				{ path: '', title: 'Tesoreria', type: 'link' },
			],
			isOnlySuperAdministrador: true
		},
		{
			title: 'PdV', icon: 'box', type: 'sub', badgeType: 'success', badgeValue: '', active: false, children: [
				{ path: '', title: 'PdV', type: 'link' },
			],
			isOnlySuperAdministrador: true
		},
		{
			headTitle1: 'Marketing',
			isOnlySuperAdministrador: true
		},
		{
			title: 'Promociones', icon: 'box', type: 'sub', badgeType: 'success', badgeValue: '', active: false, children: [
				{ path: '', title: 'Promociones', type: 'link' },
			],
			isOnlySuperAdministrador: true
		},
		{
			title: 'Landing Page', icon: 'box', type: 'sub', badgeType: 'success', badgeValue: '', active: false, children: [
				{ path: '', title: 'Landing Page', type: 'link' },
			],
			isOnlySuperAdministrador: true
		},
		{
			title: 'Email Marketing', icon: 'box', type: 'sub', badgeType: 'success', badgeValue: '', active: false, children: [
				{ path: '', title: 'Email Marketing', type: 'link' },
			],
			isOnlySuperAdministrador: true
		},
		{
			title: 'SMS Marketing', icon: 'box', type: 'sub', badgeType: 'success', badgeValue: '', active: false, children: [
				{ path: '', title: 'SMS Marketing', type: 'link' },
			],
			isOnlySuperAdministrador: true
		},

		{
			headTitle1: 'Katuq',
			headTitle2: '' + new Date().getFullYear() + ' © Julsmind S.A.S',
		},


	];

	MEGAMENUITEMS: Menu[] = [
		{
			title: 'Error Pages', type: 'sub', active: true, children: [
				{ path: 'javascript:void(0);', title: 'Error Page 400', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 401', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 403', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 404', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 500', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 503', type: 'extLink' },
			]
		},
		{
			title: 'Authentication', type: 'sub', active: false, children: [
				{ path: 'javascript:void(0);', title: 'Login Simple', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Login BG Image', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Login BG Video', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Simple Register', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Register BG Image', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Register BG Video', type: 'extLink' }
			]
		},
		{
			title: 'Usefull Pages', type: 'sub', active: false, children: [
				{ path: 'javascript:void(0);', title: 'Search Pages', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Unlock User', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Forgot Password', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Reset Password', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Maintenance', type: 'extLink' }
			]
		},
		{
			title: 'Email templates', type: 'sub', active: false, children: [
				{ path: 'http://admin.pixelstrap.com/cuba/theme/basic-template.html', title: 'Basic Email', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/email-header.html', title: 'Basic With Header', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/template-email.html', title: 'Ecomerce Template', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/template-email-2.html', title: 'Email Template 2', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/ecommerce-templates.html', title: 'Ecommerce Email', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/email-order-success.html', title: 'Order Success', type: 'extTabLink' }
			]
		},
		{
			title: 'Coming Soon', type: 'sub', active: false, children: [
				{ path: 'javascript:void(0);', title: 'Coming Simple', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Coming BG Image', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Coming BG Video', type: 'extLink' }
			]
		},
	];

	LEVELMENUITEMS: Menu[] = [
		{
			path: 'javascript:void(0);', title: 'File Manager', icon: 'git-pull-request', type: 'extLink'
		},
		{
			title: 'Users', icon: 'users', type: 'sub', active: false, children: [
				{ path: 'javascript:void(0);', title: 'All Users', icon: 'users', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'User Profile', icon: 'users', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Edit Profile', icon: 'users', type: 'extLink' },
			]
		},
		{ path: 'javascript:void(0);', title: 'Bookmarks', icon: 'heart', type: 'extLink' },
		{ path: 'javascript:void(0);', title: 'Calender', icon: 'calendar', type: 'extLink' },
		{ path: 'javascript:void(0);', title: 'Social App', icon: 'zap', type: 'extLink' }
	];

	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	megaItems = new BehaviorSubject<Menu[]>(this.MEGAMENUITEMS);
	levelmenuitems = new BehaviorSubject<Menu[]>(this.LEVELMENUITEMS);

}

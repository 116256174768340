import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {

  user: any;
  constructor() {

    this.user = JSON.parse(localStorage.getItem("user"));
  }

  ngOnInit() {
  }

  logOut() {
    localStorage.removeItem('user');
    sessionStorage.removeItem('currentCompany');
    location.href = '/login';
  };

}

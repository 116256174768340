<div class="d-flex profile-media">
  <img class="b-r-10" src="assets/images/dashboard/profile.jpg" alt="">
  <div class="media-body">
    <span>{{user.name}}</span><i class="middle fa fa-angle-down"></i>
    <p class="mb-0 font-roboto">{{user?.rol }}</p>
    <p class="mb-0 font-roboto">{{user?.company }} </p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <li><a href="#"><app-feather-icons [icon]="'user'"></app-feather-icons><span>Account </span></a></li>
  <!-- <li><a href="#"><app-feather-icons [icon]="'mail'"></app-feather-icons><span>Inbox</span></a></li>
  <li><a href="#"><app-feather-icons [icon]="'file-text'"></app-feather-icons><span>Taskboard</span></a></li>
  <li><a href="#"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span></a></li> -->
  <li><a (click)="logOut()"><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>Log out</span></a></li>
</ul>